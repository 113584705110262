import { Link, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalUserProfileContext } from "../../../App";
import { APIUtils } from "../../../helpers/utils/api";
import axiosInstance from "../../../config/axios";
import APIEndpoints from "../../../config/api/endpoints";
import displayError from "../../../helpers/displayError";
import {
  InstancesAvailableFullNameMapping,
  InstancesAvailableGVariableKey,
} from "../../../config/enums/instance";
import { UserAccountType } from "../../../config/enums/user";
import Spinner from "../../Spinner";
import { AdminRoutes } from "../../../routes/AdminRoutes";
import { SubAdminRoutes } from "../../../routes/SubAdminRoutes";
import { useLoadDependenciesBeforeRender } from "../../../helpers/hooks/UseLoadDependenciesBeforeRender";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const UpdateUser = ({ extras }) => {
  const { id: userID } = useParams();
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const { theme } = useSelector((state) => state.themeReducer);
  const [user, setUser] = useState(null);
  const [instanceStatsData, setInstanceStatsData] = useState({});
  const [canRender] = useLoadDependenciesBeforeRender({
    actionsProgressWithKeys: {
      a: user?.isFetching,
      b: instanceStatsData?.isFetching,
    },
  });

  const handleUpdateInstanceStatsData = useCallback(
    async (instance, value) => {
      const assignedInstanceID = user?.data?.assigned_instance?.id;
      if (assignedInstanceID) {
        const requestPayload = {};
        requestPayload[`${instance.toLowerCase()}_count`] = value;
        await (
          await axiosInstance()
        )
          .patch(
            APIEndpoints.user.account.instance.assigned.updateAssignedInstance(
              assignedInstanceID
            ),
            requestPayload
          )
          .then(async (response) => {
            APIUtils.fetchItemData({
              endpoint:
                APIEndpoints.user.account.instance.statistics.getRelation(
                  userID
                ),
              item: instanceStatsData,
              setItem: setInstanceStatsData,
              isFetchingValueOnTrue: instance,
            });
          })
          .catch((err) => {
            displayError(err, theme);
          });
      }
    },
    [user, instanceStatsData]
  );

  useEffect(() => {
    if (profile) {
      APIUtils.fetchItemData({
        endpoint: APIEndpoints.auth.relation.getRelation(userID),
        item: user,
        setItem: setUser,
      });
      APIUtils.fetchItemData({
        endpoint:
          APIEndpoints.user.account.instance.statistics.getRelation(userID),
        item: instanceStatsData,
        setItem: setInstanceStatsData,
      });
    }
  }, []);
  return (
    <StyledComponent theme={theme}>
      <div
        className={`mt-2 tab-content-header d-flex justify-content-end ${
          theme === "dark" &&
          "tab-content-header-dark-bg tab-content-header-dark-text"
        }`}
      >
        <Link
          className={`orange-text add-camera_btn ${
            theme === "dark" && "add-camera_btn-dark"
          }`}
          to={{
            pathname:
              user?.data?.account_type === UserAccountType.SUB_ADMINISTRATOR
                ? AdminRoutes.pathDefinitions.addSubAdmin
                : SubAdminRoutes.pathDefinitions.addSubUser,
            state: { reload: true },
          }}
        >
          + Add{" "}
          {user?.data?.account_type === UserAccountType.SUB_ADMINISTRATOR
            ? "Sub-Administrator"
            : "Sub-User"}
        </Link>
      </div>
      <h6 className='mt-3'>Functions</h6>
      {canRender !== true ? (
        <div className='d-flex justify-content-center w-100 mt-5'>
          {canRender === false && <Spinner size={1.8} />}
        </div>
      ) : (
        <div className='grid-region my-3'>
          {InstancesAvailableGVariableKey.keys().map((instance) => {
            const { isFetching, data } = instanceStatsData || {};

            const fullName = InstancesAvailableFullNameMapping[instance];
            return isFetching === false ||
              (isFetching && isFetching !== true && isFetching !== instance) ? (
              <div
                className={`dashpanel p-2 p-md-3 ${
                  theme === "dark" && "dashpanel-dark"
                }`}
              >
                {/* <div className='title'>
                                    <Link
                                        // to={`/sub_admin/cameras/${user?.data?.id}`}
                                        to={'#'}
                                        className={`title clickable ${
                                            theme === 'dark' && 'clickable-dark'
                                        }`}
                                    >
                                        {fullName} Functions
                                    </Link>
                                </div> */}

                <div
                  className='title'
                  style={{
                    color: theme === "dark" && "rgba(94, 132, 195, 1)",
                  }}
                >
                  {fullName} Functions
                </div>

                <div className='content px-3'>
                  <div>
                    Total Functions:{" "}
                    {data?.assigned[instance.toLowerCase()] || 0}
                  </div>
                  <div>
                    Available: {data?.available[instance.toLowerCase()] || 0}
                  </div>
                  <div>
                    Deployed: {data?.deployed[instance.toLowerCase()] || 0}
                  </div>
                  <div>
                    Activated: {data?.activated[instance.toLowerCase()] || 0}
                  </div>
                </div>

                <div className='input-wrapper'>
                  <label
                    htmlFor='camera'
                    style={{
                      color: theme === "dark" ? "rgba(94, 132, 195, 1)" : "",
                    }}
                  >
                    New {fullName} Functions
                  </label>
                  <input
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        const value = e.target.value;
                        if (value) {
                          handleUpdateInstanceStatsData(instance, value);
                        }
                      }
                    }}
                    type='number'
                    className={`form-control form-control-custom ${
                      theme === "dark" && "form-control-custom-dark"
                    }`}
                    id='camera'
                  />
                </div>
              </div>
            ) : (
              <div
                className={`dashpanel p-2 p-md-3 d-flex flex-column align-items-center justify-content-center ${
                  theme === "dark" && "dashpanel-dark"
                }`}
              >
                {(isFetching === true || isFetching === instance) && (
                  <Spinner size={1.5} />
                )}
              </div>
            );
          })}
        </div>
      )}
      {canRender !== true ? (
        <div className='d-flex justify-content-center w-100 mt-5'>
          {canRender === false && <Spinner size={1.8} />}
        </div>
      ) : (
        extras
      )}
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  input {
    color: ${(props) =>
      props.theme === "dark" ? "white" : "black"} !important;
  }
`;

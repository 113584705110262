import { useSelector } from "react-redux";
import styled from "styled-components";
import { Filters } from "./renderFilters";

export const FilterContainer = ({
  componentName,
  setterCallback,
  liveSearch,
  admin_br,
  backup,
  isMulti,
  btnText,
}) => {
  const { SelectedComponent } = Filters({
    componentName,
    setterCallback,
    liveSearch,
    admin_br,
    backup,
    isMulti,
    btnText,
  });
  const { theme } = useSelector((state) => state.themeReducer);
  return <StyledComponent theme={theme}>{SelectedComponent}</StyledComponent>;
};

const StyledComponent = styled.div`
  width: fit-content;
  /* margin-top: 18px; */

  .SearchContainer {
    margin: auto;
    background-color: black;
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
  }

  @media (min-width: 601px) {
    .SearchContainer {
      width: 100%;
    }
  }

  span {
    font-size: 0.5rem;
    color: ${(props) =>
      props.theme === "dark" ? "white!important" : "black!important"};
  }

  .react-select__multi-value__label {
    span {
      font-size: 0.9rem !important;
    }
  }
  .react-select__option {
    span {
      font-size: 0.9rem !important;
      text-overflow: "ellipsis";
    }
  }
  .react-select-container {
    span {
      display: none;
    }
    .react-select__loading-indicator {
      margin-right: 0px !important;
      padding: 0px !important;

      span {
        display: block !important;
      }
    }
  }
  ${
    "" /* .react_select__loading-indicator {
    margin-right: 0px !important;
    padding: 0px !important;
  }
  .react_select__loadingIndicator {
    span {
      display: block !important;
    }
  } */
  }
`;

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  BarChart,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Image1 from "../../../../../static/svg/ChartOneSvg";
import Image2 from "../../../../../static/svg/ChartTwoSvg";
import Image3 from "../../../../../static/svg/ChartThreeSvg";

// Sample data for the chart
const data = [
  {
    name: "Jan",
    LineIntrusion: 4000,
    ZoneIntrusion: 2400,
    FireSmoke: 2400,
    PPE: 2000,
  },
  {
    name: "Feb",
    LineIntrusion: 3000,
    ZoneIntrusion: 8654,
    FireSmoke: 1500,
    PPE: 1500,
  },
  {
    name: "Mar",
    LineIntrusion: 2000,
    ZoneIntrusion: 1398,
    FireSmoke: 9800,
    PPE: 1500,
  },
  {
    name: "Apr",
    LineIntrusion: 2780,
    ZoneIntrusion: 3908,
    FireSmoke: 2000,
    PPE: 5000,
  },
  {
    name: "May",
    LineIntrusion: 1890,
    ZoneIntrusion: 4800,
    FireSmoke: 2181,
    PPE: 3000,
  },
  {
    name: "Jun",
    LineIntrusion: 2390,
    ZoneIntrusion: 3800,
    FireSmoke: 2500,
    PPE: 2000,
  },
  {
    name: "Jul",
    LineIntrusion: 3390,
    ZoneIntrusion: 3300,
    FireSmoke: 2100,
    PPE: 4000,
  },
  {
    name: "Aug",
    LineIntrusion: 490,
    ZoneIntrusion: 200,
    FireSmoke: 2100,
    PPE: 4000,
  },
  {
    name: "Sep",
    LineIntrusion: 1100,
    ZoneIntrusion: 1100,
    FireSmoke: 2100,
    PPE: 4000,
  },
  {
    name: "Oct",
    LineIntrusion: 2290,
    ZoneIntrusion: 1500,
    FireSmoke: 2100,
    PPE: 4000,
  },
  {
    name: "Nov",
    LineIntrusion: 3090,
    ZoneIntrusion: 3000,
    FireSmoke: 2100,
    PPE: 4000,
  },
  {
    name: "Dec",
    LineIntrusion: 3490,
    ZoneIntrusion: 4300,
    FireSmoke: 2100,
    PPE: 4000,
  },
];

const years = [2023, 2022, 2021, 2020, 2019]; // Dummy years for dropdown

// Array of images
const changeCharts = [{ image: Image1 }, { image: Image2 }, { image: Image3 }];

const DashboardChart = () => {
  const [selectedChart, setSelectedChart] = useState(0);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleChartClick = (index) => {
    setSelectedChart(index);
  };

  const toggleYearDropdown = () => {
    setShowYearDropdown((prev) => !prev);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ChartContainer>
      <FilterSection>
        <FilterDropdown ref={dropdownRef} onClick={toggleYearDropdown}>
          By Year <span>&#9662;</span>
          {showYearDropdown && (
            <DropdownMenu>
              {years.map((year) => (
                <DropdownItem key={year}>{year}</DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </FilterDropdown>
        <ButtonGroup>
          {changeCharts.map((chart, index) => (
            <IconContainer
              key={index}
              onClick={() => handleChartClick(index)}
              isSelected={selectedChart === index}
            >
              <chart.image
                fillColor={selectedChart === index ? "white" : "#A70101"}
              />
            </IconContainer>
          ))}
        </ButtonGroup>
      </FilterSection>

      <ResponsiveContainer width="100%" height={400}>
        {selectedChart === 0 && (
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="LineIntrusion" stackId="a" fill="#800000" />
            <Bar dataKey="ZoneIntrusion" stackId="a" fill="#C81E1E" />
            <Bar dataKey="FireSmoke" stackId="a" fill="#FF6363" />
            <Bar dataKey="PPE" stackId="a" fill="#FFA8A8" />
          </BarChart>
        )}
        {selectedChart === 1 && (
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" stroke="gray" />
            <YAxis stroke="gray" />
            <Tooltip
              wrapperStyle={{ backgroundColor: "#334155", color: "#ffffff" }}
            />
            <Legend wrapperStyle={{ color: "#cbd5e1" }} />
            <Bar dataKey="LineIntrusion" fill="#800000" />
            <Bar dataKey="ZoneIntrusion" fill="#C81E1E" />
            <Bar dataKey="FireSmoke" fill="#FF6363" />
            <Bar dataKey="PPE" fill="#FFA8A8" />
          </BarChart>
        )}
        {selectedChart === 2 && (
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" stroke="gray" />
            <YAxis stroke="gray" />
            <Tooltip
              wrapperStyle={{ backgroundColor: "#334155", color: "#ffffff" }}
            />
            <Legend wrapperStyle={{ color: "#cbd5e1" }} />
            <Line
              type="monotone"
              dataKey="LineIntrusion"
              stroke="#800000" // Matching first bar color
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="ZoneIntrusion"
              stroke="#C81E1E" // Matching second bar color
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="FireSmoke"
              stroke="#FF6363" // Matching third bar color
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="PPE"
              stroke="#FFA8A8" // Matching fourth bar color
              strokeWidth={2}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default DashboardChart;

const ChartContainer = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const FilterDropdown = styled.div`
  position: relative;
  border: 1px solid #b00000;
  color: #b00000;
  height: 33px;
  width: 100%;
  max-width: 151px; /* Limit width on larger screens */
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 0.9rem;

  /* Responsive styles */
  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    font-size: 0.8rem; /* Adjust font size for readability */
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #b00000;
  border-radius: 5px;
  margin-top: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 100%; /* Full width of parent */
  max-width: 151px; /* Limit width on larger screens */
  padding: 5px;

  /* Responsive styles */
  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  border-radius: 5px;

  &:hover {
    background-color: #ffe0e0;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 20%;
      bottom: 0;
      height: 20px;
      width: 7px;
      border-radius: 5px 0 0 5px; // Apply border-radius on the right side
      background-color: #a70101;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  border: 1px solid #b00000;
  border-radius: 5px;
  overflow: hidden; // Prevents radius from overlapping
`;

const IconContainer = styled.div`
  background-color: ${(props) =>
    props.isSelected ? "#800000" : "transparent"};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.isSelected ? "#800000" : "#c81e1e")};
    color: white;
  }
`;

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LayerNotificationImage from "../../../static/img/Layer 30.png";
import Arrow from "../../../static/img/Vector 267.png";

// Data for each notification card
const notificationsData = [
  {
    heading: "Total Notifications",
    image: LayerNotificationImage,
    percentage: "20",
    arrow: Arrow,
    numbers: "2498",
    color: "#69AFFF",
    percentageColor: "#3A83D7",
  },
  {
    heading: "Total Notifications",
    image: LayerNotificationImage,
    percentage: "15",
    arrow: Arrow,
    numbers: "1340",
    color: "#42DCBD",
    percentageColor: "#31BA9F",
  },
  {
    heading: "Total Notifications",
    image: LayerNotificationImage,
    percentage: "30",
    arrow: Arrow,
    numbers: "567",
    color: "#FFBC5A",
    percentageColor: "#DE9C3B",
  },
  {
    heading: "Total Notifications",
    image: LayerNotificationImage,
    percentage: "50",
    arrow: Arrow,
    numbers: "890",
    color: "#FF5D78",
    percentageColor: "#E1445E",
  },
];

const NotificationsCards = () => {
  const { theme } = useSelector((state) => state.themeReducer);

  return (
    <StyledComponent theme={theme}>
      <div className="grid-container">
        {notificationsData.map((notification, index) => (
          <div
            className="grid-item"
            key={index}
            style={{ backgroundColor: notification.color }}
          >
            <div className="content">
              <h3 className="heading">
                <span className="total">Total</span>
                <span className="notifications">Notifications</span>
              </h3>
              <div
                className="circle-number"
                style={{ backgroundColor: notification.percentageColor }}
              >
                <p>{notification.numbers}</p>
              </div>
              <img
                src={notification.image}
                alt={notification.heading}
                className="icon"
              />
              <div className="details">
                <p
                  className="percentage"
                  style={{ backgroundColor: notification.percentageColor }}
                >
                  {notification.percentage}%
                </p>
                <p className="text">* Compared to last month</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </StyledComponent>
  );
};

export default NotificationsCards;

const StyledComponent = styled.div`
  margin-top: 1;
  padding: 1rem;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  .grid-item {
    background-color: #f1f1f1;
    border-radius: 12px;
    padding: 0.5rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s;
    position: relative;
    overflow: hidden;

    &:hover {
      transform: translateY(-5px);
    }

    .content {
      .heading {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-weight: 700;

        .total {
          display: block; // Makes 'Total' appear on its own line
          font-size: 1.2rem;
        }

        .notifications {
          display: block; // Makes 'Notifications' appear on its own line
          font-size: 1.1rem;
        }

        @media (max-width: 768px) {
          font-size: 0.9rem;
        }
      }

      .circle-number {
        position: absolute;
        top: -20px;
        right: -25px;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: rgba(255, 255, 255, 0.3);

        p {
          font-size: 32px;
          font-weight: 600;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }

        // Media query for extra large screens (above 1200px)
        @media (min-width: 1200px) {
          width: 140px; // Slightly increase the width for extra large screens
          height: 140px; // Slightly increase the height for extra large screens
        }

        // Media query for small screens (below 576px)
        @media (max-width: 576px) {
          width: 150px; // Increase the width for smaller screens
          height: 150px; // Increase the height for smaller screens
        }
      }

      .details {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 20px;

        .icon {
          width: 24px;
          height: 24px;

          @media (max-width: 768px) {
            width: 20px;
            height: 20px;
          }
        }

        .percentage {
          font-size: 0.9rem;
          font-weight: bold;
          width: 58px;
          height: 24px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 768px) {
            font-size: 0.8rem;
            width: 50px;
            height: 22px;
          }
        }

        .text {
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.8);

          @media (max-width: 768px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
`;
